/* --------------------------------------------------------------------------- */
/* head__section__top__1 START */
/* --------------------------------------------------------------------------- */
.dp .training__head__section .head__section__top__1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 60px;
}
.dp .head__section__top__1:hover .section__top__text .section__top__desc,
.dp .head__section__top__1:hover .section__top__text .section__top__cat {
    opacity: 1;
}
/* section__img */
.dp .head__section__top__1 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .head__section__top__1 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px;
}
.dp .head__section__top__1 .section__top__img__src:before {
    border-radius: 32px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.dp .head__section__top__1 .top__upper {
    z-index: 100;
}
.dp .head__section__top__1 .section__top__text {
    text-align: center;
}
/* section__cats */
.dp .head__section__top__1 .section__top__text .section__top__cat {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    flex-direction: row;
    margin: 0 auto 20px;
    width: max-content;
    max-width: 100%;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.dp .head__section__top__1 .section__top__text .section__top__cat span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dp .head__section__top__1 .section__top__text .section__top__cat span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
    /*opacity: 0.7;*/
}
.dp .head__section__top__1 .section__top__text .section__top__cat span:last-child:after {
    content: none;
}
/* section__text */
.dp .head__section__top__1 .section__top__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    padding-bottom: 15px;
    color: var(--color__text__white__1);
}
.dp .head__section__top__1 .section__top__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
}
/* section__start */
.dp .head__section__top__1 .section__top__start .section__top__start__btn {
    min-width: 400px;
    position: relative;
    background: var(--color__btn__fill__white__1);
    height: 65px;
    display: flex;
    border-radius: var(--br--16);
}
.dp .head__section__top__1 .section__top__start .section__top__start__btn a {
    color: var(--color__text__black__1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
.dp .head__section__top__1 .section__top__start .section__top__start__info {
    color: var(--color__text__white);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__1 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__2 START */
/* --------------------------------------------------------------------------- */
.dp .training__head__section .head__section__top__2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
}
.dp .head__section__top__2:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.dp .head__section__top__2:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.dp .head__section__top__2:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.dp .head__section__top__2 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .head__section__top__2 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px;
}
.dp .head__section__top__2 .section__top__img__src:before {
    border-radius: 32px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.dp .head__section__top__2 .top__upper {
    z-index: 100;
    padding: 0 50px 0 50px;
    display: flex;
    grid-gap: 30px;
    flex-wrap: wrap;
}
/* section__discount */
.dp .head__section__top__2 .section__top__text .upper__hd {
    display: flex;
}
.dp .head__section__top__2 .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    right: 80px;
    top: -18px;
}
.dp .head__section__top__2 .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--23);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.dp .head__section__top__2 .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-gap: 100px;
    grid-template-columns: auto 25%;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 15px;
    color: var(--color__text__white__1);
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    margin-bottom: 35px;
}
/* section__button */
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.dp .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.dp .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dp .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.dp .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets {
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 30px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    display: flex;
    flex-direction: column;
    opacity: 0.8;
    padding-left: 60px;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 22px;
    line-height: 1;
    padding-bottom: 5px;
}
.dp .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__2 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__3 START */
/* --------------------------------------------------------------------------- */
.dp .training__head__section .head__section__top__3 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
}
.dp .head__section__top__3:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.dp .head__section__top__3:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.dp .head__section__top__3:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.dp .head__section__top__3 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .head__section__top__3 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px;
}
.dp .head__section__top__3 .section__top__img__src:before {
    border-radius: 32px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.dp .head__section__top__3 .top__upper {
    z-index: 100;
    padding: 0 50px 0 50px;
    display: grid;
}
/* section__discount */
.dp .head__section__top__3 .section__top__text .upper__hd {
    display: flex;
}
.dp .head__section__top__3 .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    right: 80px;
    top: -18px;
}
.dp .head__section__top__3 .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--23);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.dp .head__section__top__3 .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-gap: 100px;
    grid-template-columns: auto 25%;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 15px;
    color: var(--color__text__white__1);
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    margin-bottom: 35px;
}
/* section__button */
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.dp .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.dp .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dp .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.dp .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets {
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
    justify-content: center;
    align-items: center;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    opacity: 0.8;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.dp .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    display: flex;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__3 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__discount START */
/* --------------------------------------------------------------------------- */
.dp .training__head__section .head__section__top__discount {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
}
.dp .head__section__top__discount:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.dp .head__section__top__discount:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.dp .head__section__top__discount:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.dp .head__section__top__discount .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .head__section__top__discount .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 32px;
}
.dp .head__section__top__discount .section__top__img__src:before {
    border-radius: 32px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.dp .head__section__top__discount .top__upper {
    z-index: 100;
    padding: 0 50px 0 50px;
    display: grid;
}
/* section__discount */
.dp .head__section__top__discount .section__top__text .upper__hd {
    display: flex;
}
.dp .head__section__top__discount .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    right: 80px;
    top: -18px;
}
.dp .head__section__top__discount .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--23);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.dp .head__section__top__discount .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-gap: 100px;
    grid-template-columns: auto 25%;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 15px;
    color: var(--color__text__white__1);
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    margin-bottom: 35px;
}
/* section__button */
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.dp .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.dp .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dp .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.dp .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets {
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
    justify-content: center;
    align-items: center;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    opacity: 0.8;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.dp .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    display: flex;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__discount END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__1 START */
/* --------------------------------------------------------------------------- */
.mb .training__head__section .head__section__top__1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 80px 20px;
    grid-gap: 20px;
}
.mb .head__section__top__1:hover .section__top__text .section__top__desc,
.mb .head__section__top__1:hover .section__top__text .section__top__cat {
    opacity: 1;
}
/* section__img */
.mb .head__section__top__1 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.mb .head__section__top__1 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mb .head__section__top__1 .section__top__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.mb .head__section__top__1 .top__upper {
    z-index: 100;
}
.mb .head__section__top__1 .section__top__text {
    text-align: center;
}
/* section__cats */
.mb .head__section__top__1 .section__top__text .section__top__cat {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    flex-direction: row;
    margin: 0 auto 40px;
    width: max-content;
    max-width: 100%;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.mb .head__section__top__1 .section__top__text .section__top__cat span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mb .head__section__top__1 .section__top__text .section__top__cat span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
    /*opacity: 0.7;*/
}
.mb .head__section__top__1 .section__top__text .section__top__cat span:last-child:after {
    content: none;
}
/* section__text */
.mb .head__section__top__1 .section__top__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    padding-bottom: 10px;
    text-align: center;
    font-size: 30px;
    color: var(--color__text__white__1);
}
.mb .head__section__top__1 .section__top__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    font-size: var(--f--17);
    text-align: center;
}
/* section__start */
.mb .head__section__top__1 .section__top__start .section__top__start__btn {
    position: relative;
    background: var(--color__btn__fill__white__1);
    display: flex;
    border-radius: var(--br--16);
    padding: 20px 100px;
}
.mb .head__section__top__1 .section__top__start .section__top__start__btn a {
    color: var(--color__text__black__1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
.mb .head__section__top__1 .section__top__start .section__top__start__info {
    color: var(--color__text__white__1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__1 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__2 START */
/* --------------------------------------------------------------------------- */
.mb .training__head__section .head__section__top__2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 100px 0;
}
.mb .head__section__top__2:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.mb .head__section__top__2:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.mb .head__section__top__2:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.mb .head__section__top__2 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.mb .head__section__top__2 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mb .head__section__top__2 .section__top__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.mb .head__section__top__2 .top__upper {
    z-index: 100;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
}
/* section__discount */
.mb .head__section__top__2 .section__top__text .upper__hd {
    display: flex;
}
.mb .head__section__top__2 .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    right: 80px;
    top: -18px;
}
.mb .head__section__top__2 .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--23);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.mb .head__section__top__2 .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 10px;
    color: var(--color__text__white__1);
    font-size: 30px;
    text-align: center;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    font-size: var(--f--17);
    margin-bottom: 25px;
    text-align: center;
}
/* section__button */
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.mb .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.mb .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mb .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.mb .head__section__top__2 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets {
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 30px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    display: flex;
    flex-direction: column;
    opacity: 0.8;
    padding-left: 60px;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 22px;
    line-height: 1;
    padding-bottom: 5px;
}
.mb .head__section__top__2 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__2 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__3 START */
/* --------------------------------------------------------------------------- */
.mb .training__head__section .head__section__top__3 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
}
.mb .head__section__top__3:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.mb .head__section__top__3:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.mb .head__section__top__3:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.mb .head__section__top__3 .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.mb .head__section__top__3 .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mb .head__section__top__3 .section__top__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.mb .head__section__top__3 .top__upper {
    z-index: 100;
    padding: 0 20px;
    display: grid;
}
/* section__discount */
.mb .head__section__top__3 .section__top__text .upper__hd {
    display: flex;
}
.mb .head__section__top__3 .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    right: 80px;
    top: -18px;
}
.mb .head__section__top__3 .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--23);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.mb .head__section__top__3 .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 10px;
    color: var(--color__text__white__1);
    text-align: center;
    font-size: 30px;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    line-height: 1.2;
    color: var(--color__text__white__1);
    opacity: 0.8;
    margin-bottom: 35px;
    font-size: var(--f--17);
    text-align: center;
}
/* section__button */
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.mb .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.mb .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mb .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.mb .head__section__top__3 .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets {
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
    justify-content: center;
    align-items: center;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    opacity: 0.8;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.mb .head__section__top__3 .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    display: flex;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__3 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* head__section__top__discount START */
/* --------------------------------------------------------------------------- */
.mb .training__head__section .head__section__top__discount {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
}
.mb .head__section__top__discount:hover .section__top__text .upper__cn .upper__cn__text .section__top__desc,
.mb .head__section__top__discount:hover .section__top__text .upper__cn .section__top__cats .upper__cn__cats,
.mb .head__section__top__discount:hover .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    opacity: 1;
}
/* section__img */
.mb .head__section__top__discount .section__top__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.mb .head__section__top__discount .section__top__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mb .head__section__top__discount .section__top__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, .6) 0%);
}
.mb .head__section__top__discount .top__upper {
    z-index: 100;
    padding: 0 20px;
    display: grid;
}
/* section__discount */
.mb .head__section__top__discount .section__top__text .upper__hd {
    display: flex;
}
.mb .head__section__top__discount .section__top__text .upper__hd .cn__hd__discount {
    padding: 8px 25px;
    border-radius: var(--br--16);
    background: var(--color__bg__fill__violet_1);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mb .head__section__top__discount .section__top__text .upper__hd .cn__hd__discount span {
    font-size: var(--f--17);
    font-weight: var(--fw--500);
    text-align: center;
    color: var(--color__text__white__1);
}
/* section__text */
.mb .head__section__top__discount .section__top__text .upper__cn {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__title {
    line-height: 1;
    font-weight: var(--fw--900);
    margin-bottom: 10px;
    color: var(--color__text__white__1);
    font-size: 30px;
    text-align: center;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__desc {
    color: var(--color__text__white__1);
    opacity: 0.8;
    margin-bottom: 25px;
    text-align: center;
    font-size: var(--f--17);
}
/* section__button */
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__button {
    background: var(--color__btn__fill__white__1);
    padding: 20px 100px;
    border-radius: var(--br--16);
    display: inline-block;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__button a {
    color: var(--color__text__black__1);
    display: flex;
    justify-content: center;
    font-weight: var(--fw--400);
    font-size: var(--f--17);
    line-height: 1;
}
/* section__cats */
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__text .section__top__cats {
    padding-bottom: 50px;
}
.mb .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats {
    border: .05rem solid var(--color__text__white__1);
    padding: 12px 20px;
    border-radius: var(--br--16);
    display: flex;
    line-height: 1;
    font-size: var(--f--17);
    color: var(--color__text__white__1);
    opacity: 0.8;
}
.mb .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mb .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:last-child:after {
    content: none;
}
.mb .head__section__top__discount .section__top__text .upper__cn .section__top__cats .upper__cn__cats span:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color__bg__fill__white__1);
    position: relative;
    left: 0;
    top: 0;
    margin-inline: 10px;
}
/* section__bullets */
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets {
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
    border-left: 1px solid #b6b6b6;
    justify-content: center;
    align-items: center;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    opacity: 0.8;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__count {
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.mb .head__section__top__discount .section__top__text .upper__cn .upper__cn__bullets .cn__bullets__items .cn__bullets__item .bullet__item__desc {
    color: var(--color__text__white__1);
    font-size: var(--f--17);
    opacity: 0.7;
    align-items: center;
    justify-content: center;
    display: flex;
}
/* --------------------------------------------------------------------------- */
/* head__section__top__discount END */
/* --------------------------------------------------------------------------- */