/* --------------------------------------------------------------------------- */
/* training__about__section START */
/* --------------------------------------------------------------------------- */
.dp .training__about__section .training__about__content {
    margin-bottom: 120px;
}
.dp .training__about__content .content__head {
    padding-bottom: 45px;
}
.dp .training__about__content .content__data .block__about {
    display: grid;
    margin: 0 auto;
    grid-gap: 35px;
    grid-template-rows: auto;
    background: var(--color__bg__fill__gray__1);
    padding: 30px 40px;
    border-radius: var(--br--24);
    justify-content: center;
    align-items: center;
}
.dp .training__about__content .content__data .block__about .block__photo {
    background: center no-repeat;
    background-size: cover;
    border-radius: 205px;
    box-shadow: 0 0 80px 5px whitesmoke inset;
    width: 350px;
    height: 350px;
}
.dp .training__about__content .content__data .block__about .block__photo .photo__text {
    font-size: 20px;
    color: var(--color__text__gray__1);
}
.dp .training__about__content .content__data .block__about .block__photo img {
    width: 90%;
    background: center no-repeat;
    background-size: cover;
    border-radius: 185px;
    box-shadow: 0 0 8px 8px white inset;
}
.dp .training__about__content .content__data .block__about .block__text {
    color: var(--color__text__gray__1);
}

.mb .training__about__section .training__about__content {
    margin-bottom: 120px;
    padding: 0 20px;
}
.mb .training__about__content .content__head {
    padding-bottom: 45px;
}
.mb .training__about__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__about__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__about__content .content__data .block__about {
    display: grid;
    margin: 0 auto;
    grid-gap: 35px;
    grid-template-rows: auto;
    background: var(--color__bg__fill__gray__1);
    padding: 25px 25px;
    border-radius: var(--br--24);
    justify-content: center;
    align-items: center;
}
.mb .training__about__content .content__data .block__about .block__photo {
    background: center no-repeat;
    background-size: cover;
    border-radius: 205px;
    box-shadow: 0 0 80px 5px whitesmoke inset;
    width: 300px;
    height: 300px;
    margin: 0 auto;
}
.mb .training__about__content .content__data .block__about .block__photo .photo__text {
    font-size: 20px;
    color: var(--color__text__gray__1);
}
.mb .training__about__content .content__data .block__about .block__photo img {
    width: 90%;
    background: center no-repeat;
    background-size: cover;
    border-radius: 185px;
    box-shadow: 0 0 8px 8px white inset;
}
.mb .training__about__content .content__data .block__about .block__text {
    color: var(--color__text__gray__1);
    font-size: var(--f--17);
}
/* --------------------------------------------------------------------------- */
/* training__about__section END */
/* --------------------------------------------------------------------------- */