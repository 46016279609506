.dp .base__bg {
  background: radial-gradient(16.81% 38.78% at 18.92% 3.69%, rgb(77, 4, 255) 0%, rgba(76, 5, 255, 0) 100%), radial-gradient(19.93% 45.99% at 32.36% 87.9%, rgba(4, 255, 41, 0.1) 0%, rgba(5, 255, 193, 0) 100%, rgba(5, 255, 43, 0) 100%), radial-gradient(22.95% 52.96% at 99.03% 62.82%, rgb(255, 4, 230) 0%, rgba(255, 5, 230, 0) 100%), linear-gradient(247.98deg, rgba(35, 255, 233, 0.247) 20.32%, rgba(91, 191, 255, 0.208) 33.65%, rgba(242, 166, 255, 0.176) 56.4%, rgba(255, 77, 68, 0.184) 82.18%), linear-gradient(99.95deg, rgba(255, 223, 237, 0.8) 0%, rgba(255, 228, 246, 0.8) 23.43%, rgba(238, 226, 255, 0.8) 52.6%, rgba(230, 226, 255, 0.8) 80.21%, rgba(210, 233, 255, 0.8) 100%);
  background-blend-mode: soft-light, difference, soft-light, multiply, normal;
  opacity: 0.2;
}
.dp .base__container {
  /*padding-right: 15px;*/
  /*padding-left: 15px;*/
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  min-width: 640px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.dp .base__container .base__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dp .screen__block {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /*min-height: 100vh;*/
  /* padding-right: 60px; */
  /* padding-left: 60px; */
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  flex-direction: column;
}
.dp .block__container {
  max-width: 1280px;
  margin: 0 auto;
}
.dp .screen__block .block__header {
  /*position: absolute;*/
  /*left: 0%;*/
  /*top: 0%;*/
  /*right: 0%;*/
  /*bottom: auto;*/
  /*!* z-index: 9; *!*/
  /*!* display: -webkit-box; *!*/
  /*!* display: -webkit-flex; *!*/
  /*display: -ms-flexbox;*/
  /*!* display: flex; *!*/
  /*padding-top: 60px;*/
  /*!* padding-right: 60px; *!*/
  /*!* padding-left: 60px; *!*/
  /*-webkit-box-pack: justify;*/
  /*-webkit-justify-content: space-between;*/
  /*-ms-flex-pack: justify;*/
  /*justify-content: space-between;*/
  /*-webkit-box-align: center;*/
  /*-webkit-align-items: center;*/
  /*-ms-flex-align: center;*/
  /*align-items: center;*/
}

@media only screen and (min-width: 1340px) {
  .dp .base__container {
    max-width: 1280px;
  }
  /* block__cards */
  .dp .block__cards__list .block__card__items {
    grid-template-columns: repeat(4, 1fr);
  }
  .dp .block__card__items .card__item__block-1 {
    grid-column: span 2;
  }
  /* title */
  .dp .block__main .block__main__info .main__info__text,
  .dp .section__top__text .section__top__title,
  .dp .section__container .section__title {
    font-size: 60px;
  }
  /* trpage__head */
  .dp .training__head__section .head__section__top__1,
  .dp .training__head__section .head__section__top__2,
  .dp .training__head__section .head__section__top__3 {
    min-height: 550px;
  }
  .dp .head__section__top__1 .section__top__text {
    width: 80%;
  }
  .dp .section__top__text .section__top__desc,
  .dp .section__container .section__desc {
    font-size: var(--f--21);
  }
  /* trpage__head__bullets */
  .training__head__section .head__section__bullets {
    width: 65%;
    margin-top: 0;
    border-radius: 0 0 24px 24px;
    margin: 0 auto;
  }
  .dp .section__bullets__items .section__bullets__item .bullet__count {
    font-size: 45px;
  }
  .dp .section__bullets__items .section__bullets__item .bullet__desc {
    font-size: var(--f--17);
  }
  /* training__form__section */
  .dp .training__form__content .content__data .block__form .block__form__info .form__info__title {
    font-size: 40px;
  }
  .dp .training__form__content .content__data .block__form .block__form__lead form {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  /* training__about__section */
  .dp .training__about__content .content__data .block__about {
    grid-template-columns: auto 350px;
  }
  .dp .training__about__content .content__data .block__about .block__text {
    font-size: var(--f--17);
  }
  /* training__problems__section */
  .dp .training__problems__content .container__bullets .bullets__items {
    grid-template-columns: repeat(4, 1fr);
  }
  /* training__forwho__section */
  .dp .training__forwho__content .container__bullets .bullets__items {
    grid-template-columns: repeat(3, 1fr);
  }
  .dp .training__forwho__content .bullets__item .item__body .item__text .item__text__desc {
    font-size: var(--f--16);
  }
  .dp .training__forwho__content .bullets__item .item__body {
    padding: 0 20px 0 20px;
  }
  /* training__steps__section */
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    font-size: 19px;
  }
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items {
    grid-template-columns: repeat(3, 1fr);
  }
  /* training__author__section */
  .dp .training__author__content .content__data .block__author {
    grid-template-columns: 400px auto;
  }
  /* training__video__section */
  .dp .training__video__content .content__data .block__video {
    width: 70%;
  }
}
@media only screen and (max-width: 1340px) and (min-width: 1030px) {
  .dp .base__container {
    max-width: 960px;
  }
  /* block__cards */
  .dp .block__cards__list .block__card__items {
    grid-template-columns: repeat(3, 1fr);
  }
  .dp .block__card__items .card__item__block-1 {
    grid-column: span 2;
  }
  /* title */
  .dp .block__main .block__main__info .main__info__text,
  .dp .section__top__text .section__top__title,
  .dp .section__container .section__title {
    font-size: 45px;
  }
  /* trpage__head */
  .training__head__section .head__section__top__1,
  .training__head__section .head__section__top__2 {
    min-height: 400px;
  }
  .dp .head__section__top__1 .section__top__text {
    width: 85%;
  }
  .dp .section__top__text .section__top__desc,
  .dp .section__container .section__desc {
    font-size: 21px;
  }
  /* trpage__head__bullets */
  .training__head__section .head__section__bullets {
    width: 75%;
    margin-top: 0;
    border-radius: 0 0 24px 24px;
    margin: 0 auto;
  }
  .dp .section__bullets__items .section__bullets__item .bullet__count {
    font-size: 45px;
  }
  .dp .section__bullets__items .section__bullets__item .bullet__desc {
    font-size: var(--f--17);
  }
  /* training__form__section */
  .dp .training__form__content .content__data .block__form .block__form__info .form__info__title {
    font-size: 30px;
  }
  .dp .training__form__content .content__data .block__form .block__form__lead form {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  /* training__about__section */
  .dp .training__about__content .content__data .block__about {
    grid-template-columns: auto 350px;
  }
  .dp .training__about__content .content__data .block__about .block__text {
    font-size: var(--f--17);
  }
  /* training__problems__section */
  .dp .training__problems__content .container__bullets .bullets__items {
    grid-template-columns: repeat(3, 1fr);
  }
  /* training__forwho__section */
  .dp .training__forwho__content .container__bullets .bullets__items {
    grid-template-columns: repeat(2, 1fr);
  }
  .dp .training__forwho__content .bullets__item .item__body .item__text .item__text__desc {
    font-size: var(--f--16);
  }
  .dp .training__forwho__content .bullets__item .item__body {
    padding: 0 20px 0 20px;
  }
  /* training__steps__section */
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    font-size: var(--f--17);
  }
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items {
    grid-template-columns: repeat(3, 1fr);
  }
  /* training__author__section */
  .dp .training__author__content .content__data .block__author {
    grid-template-columns: 400px auto;
  }
  /* training__video__section */
  .dp .training__video__content .content__data .block__video {
    width: 70%;
  }
}
@media only screen and (max-width: 1030px) {
  .dp .base__container {
    max-width: 640px;
  }
  /* block__cards */
  .dp .block__cards__list .block__card__items {
    grid-template-columns: repeat(2, 1fr);
  }
  .dp .block__card__items .card__item__block-1 {
    grid-column: span 2;
  }
  /* title */
  .dp .block__main .block__main__info .main__info__text,
  .dp .section__top__text .section__top__title,
  .dp .section__container .section__title {
    font-size: 32px;
  }
  /* trpage__head */
  .training__head__section .head__section__top__1,
  .training__head__section .head__section__top__2 {
    min-height: 350px;
  }
  .dp .head__section__top__1 .section__top__text {
    width: 90%;
  }
  .dp .section__top__text .section__top__desc,
  .dp .section__container .section__desc {
    font-size: var(--f--17);
  }
  /* trpage__head__bullets */
  .training__head__section .head__section__bullets {
    width: 100%;
    margin-top: 25px;
    border-radius: var(--br--24);
  }
  .dp .section__bullets__items .section__bullets__item .bullet__count {
    font-size: 40px;
  }
  .dp .section__bullets__items .section__bullets__item .bullet__desc {
    font-size: var(--f--16);
  }
  /* training__form__section */
  .dp .training__form__content .content__data .block__form .block__form__info .form__info__title {
    font-size: var(--f--27);
  }
  .dp .training__form__content .content__data .block__form .block__form__lead form {
    grid-gap: 15px;
  }
  .dp .training__form__content .content__data .block__form .block__form__lead .form__button {
    margin-top: 15px;
  }
  /* training__about__section */
  .dp .training__about__content .content__data .block__about .block__photo {
    margin: 0 auto;
  }
  .dp .training__about__content .content__data .block__about .block__text {
    font-size: var(--f--17);
  }
  /* training__problems__section */
  .dp .training__problems__content .container__bullets .bullets__items {
    grid-template-columns: repeat(2, 1fr);
  }
  /* training__forwho__section */
  .dp .training__forwho__content .container__bullets .bullets__items {
    grid-template-columns: repeat(1, 1fr);
  }
  .dp .training__forwho__content .bullets__item .item__body .item__text .item__text__desc {
    font-size: var(--f--17);
  }
  .dp .training__forwho__content .bullets__item .item__body {
    padding: 0 40px 0 20px;
  }
  /* training__steps__section */
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    font-size: var(--f--17);
  }
  .dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items {
    grid-template-columns: repeat(2, 1fr);
  }
  /* training__video__section */
  .dp .training__video__content .content__data .block__video {
    width: 100%;
  }
}

.dp .block__container {
  max-width: 1280px;
  margin: 0 auto;
}
.dp .start__screen {}

/* --------------------------------------------------------------------------- */
/* block__cards__list START */
/* --------------------------------------------------------------------------- */
.dp .block__cards__list {
  padding-bottom: 100px;
}
.dp .block__first {
  padding-top: 60px;
}
.dp .block__cards__list .block__cards__list__head {
  display: grid;
  grid-auto-rows: min-content;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}
.dp .block__cards__list .head__page__all {
  align-items: center;
  grid-template-columns: 1fr 160px;
}
.dp .block__cards__list .head__page__cats {
  align-items: center;
  margin-bottom: 20px;
}
.dp .block__cards__list .head__page__cats span {
  text-transform: lowercase;
}
.dp .block__cards__list .block__cards__list__head .block__card__head__title {
  line-height: 1;
  font-weight: var(--fw--600);
  font-size: 37px;
}
.dp .block__cards__list .block__cards__list__head .block__card__head__link {
  display: flex;
  justify-content: flex-end;
}
.dp .block__cards__list .block__cards__list__head .block__card__head__link a {
  border-radius: 12px;
  padding: 13px 20px;
  background: var(--color__btn__fill__gray__1);
  color: var(--color__text__gray__3);
}
.dp .block__cards__list .block__cards__list__head .block__card__head__link a:hover {
  background: var(--color__btn__fill__gray__2);
}
.dp .block__cards__list .block__card__items {
  position: relative;
  padding-top: 40px;
  display: grid;
  min-width: 100%;
  margin: 0 auto;
  grid-template-rows: auto;
  grid-gap: 20px;
}
.dp .block__card__items .block__card__item {
  position: relative;
  -webkit-transition: box-shadow .4s ease;
  transition: box-shadow .4s ease;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-transition: box-shadow .4s ease;
  transition: box-shadow .4s ease;
  border-radius: var(--br--24);
}
/* --------------------------------------------------------------------------- */
/* block__cards__list END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* training__page START */
/* --------------------------------------------------------------------------- */
.dp .training__page .training__page__container {
  padding-top: 30px;
}
/* --------------------------------------------------------------------------- */
/* training__page END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* docs__page START */
/* --------------------------------------------------------------------------- */
.dp .docs__page {
  padding: 30px 0;
  flex-grow: 1;
}
.dp .docs__page .docs__title {
  font-size: 37px;
  font-weight: var(--fw--600);
}
.dp .docs__page .docs__date {
  justify-content: flex-end;
  display: flex;
  padding-top: 30px;
  align-items: flex-end;
  flex-direction: column;
}
.dp .docs__page .docs__text {
  padding: 30px 5px 0 5px;
}
.dp .section__container {
  margin-bottom: 150px;
}
.dp .section__container .section__title {
  line-height: 1;
  font-weight: var(--fw--700);
  color: var(--color__text__black__1);
}
.dp .section__container .section__desc {
  padding-top: 10px;
  color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* docs__page END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* error__page START */
/* --------------------------------------------------------------------------- */
.dp .error__page {
}
.dp .error__page .error__info {
}
.dp .error__page .error__info .error__info__data {
}
.dp .error__page .error__info .error__info__data .data__title {
  font-size: 30px;
  line-height: 1;
  font-weight: var(--fw--600);
  padding-bottom: 15px;
  color: var(--color__text__black__1);
}
.dp .error__page .error__info .error__info__data .data__desc {
  font-size: var(--f--17);
  color: var(--color__text__gray__1);
  padding-bottom: 35px;
}
.dp .error__page .error__info .error__info__data .data__button {
}
.dp .error__page .error__info .error__info__data .data__button a {
  border: none;
  border-radius: 12px;
  padding: 13px 20px;
  background: var(--color__btn__fill__black__2);
  color: var(--color__text__white__1);
}
/* --------------------------------------------------------------------------- */
/* error__page END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* training__dash__section START */
/* --------------------------------------------------------------------------- */
.dp .training__dash__section .training__dash__content {
  margin-bottom: 120px;
}
.dp .training__dash__content .content__head {
  padding-bottom: 45px;
}
.dp .training__dash__content .content__data .block__demo {
  display: grid;
  margin: 0 auto;
  grid-gap: 25px;
  grid-template-columns: 65% auto;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 120px;
}
.dp .training__dash__content .content__data .block__inversion {
  grid-template-columns: auto 65%;
  grid-row: 1;
}
.dp .training__dash__content .content__data .block__demo .demo__img {
  display: grid;
  justify-content: center;
  align-items: center;
}
.dp .training__dash__content .content__data .block__demo .demo__img img {
  width: 93%;
  background: center no-repeat;
  background-size: cover;
  border-radius: var(--br--24);
}
.dp .training__dash__content .content__data .block__demo .demo__info .demo__info__title {
  font-size: var(--f--25);
  font-weight: var(--fw--700);
  color: var(--color__text__black__1);
}
.dp .training__dash__content .content__data .block__demo .demo__info .demo__info__desc {
  font-size: 18px;
  color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* training__dash__section END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* training__questions__section START */
/* --------------------------------------------------------------------------- */
.dp .training__questions__section .training__questions__content {
  margin-bottom: 120px;
}
.dp .training__questions__content .content__head {
  padding-bottom: 45px;
  text-align: center;
}
.dp .training__questions__content .content__data .questions__items .question__item {
  background: var(--color__bg__fill__gray__2);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: var(--br--16);
}
.dp .training__questions__content .content__data .questions__items .question__item .question__data .data__question {
  color: var(--color__text__black__1);
  font-size: 19px;
  font-weight: var(--fw--600);
}
.dp .training__questions__content .content__data .questions__items .question__item .question__data .data__answer {
  padding-top: 10px;
  color: var(--color__text__gray__1);
  font-size: var(--f--17);
  font-weight: var(--fw--400);
  display: none;
}
/* --------------------------------------------------------------------------- */
/* training__questions__section END */
/* --------------------------------------------------------------------------- */



/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* MOBILE */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------- */
.mb .base__container {
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  min-width: 320px;
}

/* --------------------------------------------------------------------------- */
/* block__main START */
/* --------------------------------------------------------------------------- */
.mb .block__main {
  padding-bottom: 30px;
}
.mb .block__main .block__main__container {
  justify-content: space-between;
  grid-gap: 20px;
  grid-template-columns: 60% auto;
  grid-template-rows: auto;
}
.mb .block__main .block__main__info {
  justify-content: center;
  align-items: center;
  display: flex;
}
.mb .block__main .block__main__info .main__info__text {
  line-height: 1;
  font-weight: var(--fw--600);
  text-align: center;
  font-size: 24px;
  min-height: 80px;
  padding: 0 15px;
}
.mb .block__main .block__main__screen .main__screen__container {
  align-items: center;
  right: 0;
  position: relative;
  overflow: hidden;
  display: none;
}
.mb .block__main .block__main__screen img {
  width: 288%;
  background: #f3f3f2;
  padding: 30px;
  border-radius: 18px;
}
/* --------------------------------------------------------------------------- */
/* block__main END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* block__cards__list START */
/* --------------------------------------------------------------------------- */
.mb .block__cards {

}
.mb .block__first {
  padding-top: 60px;
}
.mb .block__cards__list:last-child {
  padding-bottom: 0;
}
.mb .block__cards__list {
  padding-bottom: 100px;
}
.mb .block__cards__list .block__cards__list__head {
  display: grid;
  grid-auto-rows: min-content;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 0 20px 0 20px;
}
.mb .block__cards__list .head__page__all {
  align-items: center;
  grid-template-columns: 1fr 160px;
}
.mb .block__cards__list .head__page__cats {
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
}
.mb .block__cards__list .head__page__cats span {
  text-transform: lowercase;
}
.mb .block__cards__list .block__cards__list__head .block__card__head__title {
  line-height: 1;
  font-weight: var(--fw--600);
  font-size: 24px;
}
.mb .block__cards__list .block__cards__list__head .block__card__head__link {
  display: flex;
  justify-content: flex-end;
}
.mb .block__cards__list .block__cards__list__head .block__card__head__link a {
  border-radius: 12px;
  padding: 13px 20px;
  background: var(--color__btn__fill__gray__1);
  color: var(--color__text__gray__3);
}
.mb .block__cards__list .block__cards__list__head .block__card__head__link a:hover {
  background: var(--color__btn__fill__gray__2);
}
.mb .block__cards__list .block__card__items {
  position: relative;
  padding-top: 40px;
  display: grid;
  min-width: 100%;
  margin: 0 auto;
  grid-template-rows: auto;
  grid-gap: 35px;
}
.mb .block__card__items .block__card__item {
  position: relative;
  -webkit-transition: box-shadow .4s ease;
  transition: box-shadow .4s ease;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  -webkit-transition: box-shadow .4s ease;
  transition: box-shadow .4s ease;
  border-radius: var(--br--24);
}
/* --------------------------------------------------------------------------- */
/* block__cards__list END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* training__page START */
/* --------------------------------------------------------------------------- */
.mb .training__page .training__page__container {
  padding-top: 30px;
}
.mb .head__section__bullets .section__bullets__items {
  display: grid;
  grid-gap: 20px;
  padding: 0 20px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 120px;
}
.mb .section__bullets__items .section__bullets__item {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0 35px;
  background: var(--color__bg__fill__gray__2);
  border-radius: var(--br--24);
}
.mb .section__bullets__items .section__bullets__item .bullet__desc {
  color: var(--color__text__gray__1);
  font-size: var(--f--17);
}
.mb .section__bullets__items .section__bullets__item .bullet__count {
  font-weight: var(--fw--600);
  color: var(--color__text__black__1);
  font-size: 40px;
}
/* --------------------------------------------------------------------------- */
/* training__page END */
/* --------------------------------------------------------------------------- */