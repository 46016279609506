/* --------------------------------------------------------------------------- */
/* training__form__section START */
/* --------------------------------------------------------------------------- */
.dp .training__form__content .content__head {
    padding-bottom: 45px;
    text-align: center;
}

.dp .training__form__content .content__data .block__form {
    background: var(--color__bg__fill__gray__3);
    padding: 60px 60px 60px;
    border-radius: var(--br--24);
}
.dp .training__form__content .content__data .block__form .block__form__info {
    margin-bottom: 45px;
    text-align: left;
}
.dp .training__form__content .content__data .block__form .block__form__info .form__info__title {
    line-height: 1;
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
}
.dp .training__form__content .content__data .block__form .block__form__info .form__info__desc {
    padding-top: 10px;
    color: var(--color__text__white__1);
    line-height: 1.2;
    opacity: 0.8;
    font-size: var(--f--17);
}
.dp .training__form__content .content__data .block__form .block__form__lead {
    margin-bottom: 40px;
}
.dp .training__form__content .content__data .block__form .block__form__lead form {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
}

.dp .training__form__content .content__data .block__form .block__form__confirm {
    color: var(--color__text__white__1);
    opacity: 0.8;
    font-size: var(--f--16);
}
.dp .training__form__content .content__data .block__form .block__form__confirm a {
    color: var(--color__text__white__1);
    opacity: 1;
    text-decoration: underline;
}

/*button*/
.dp .training__form__content .content__data .block__form .block__form__lead .form__button {
    display: flex;
    justify-content: center;
}
.dp .training__form__content .content__data .block__form .block__form__lead .form__button button {
    width: 100%;
    border-radius: 12px;
    background: var(--color__bg__fill__white__1);
    color: var(--color__bg__fill__gray__3);
    height: 60px;
}

/*input*/
.dp .training__form__content .content__data .block__form .block__form__lead .form__input {

}
.dp .training__form__content .content__data .block__form .block__form__lead .form__input input {
    background: var(--color__bg__fill__gray__3);
    height: 60px;
    border-radius: 12px;
    color: var(--color__text__white__1);
    --input-text-color: var(--color__text__white__1);
}
.dp .training__form__content .content__data .block__form .block__form__lead .form__input input:focus {
    border: 1px solid var(--color__text__white__1);
}

.mb .training__form__section .training__form__content {
    margin-bottom: 120px;
}
.mb .training__form__content .content__head {
    padding-bottom: 45px;
    text-align: center;
}

.mb .training__form__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__form__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}


.mb .training__form__content .content__data .block__form {
    background: var(--color__bg__fill__gray__3);
    padding: 50px 20px;
}
.mb .training__form__content .content__data .block__form .block__form__info {
    margin-bottom: 45px;
    text-align: center;
}
.mb .training__form__content .content__data .block__form .block__form__info .form__info__title {
    line-height: 1;
    font-weight: var(--fw--600);
    color: var(--color__text__white__1);
    font-size: 24px;
}
.mb .training__form__content .content__data .block__form .block__form__info .form__info__desc {
    padding-top: 10px;
    color: var(--color__text__white__1);
    line-height: 1.2;
    opacity: 0.8;
    font-size: var(--f--17);
}
.mb .training__form__content .content__data .block__form .block__form__lead form {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    grid-gap: 15px;
    padding: 0 15px;
}

/*button*/
.mb .training__form__content .content__data .block__form .block__form__lead .form__button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.mb .training__form__content .content__data .block__form .block__form__lead .form__button button {
    width: 100%;
    border-radius: 12px;
    background: var(--color__bg__fill__white__1);
    color: var(--color__bg__fill__gray__3);
    height: 60px;
}

/*input*/
.mb .training__form__content .content__data .block__form .block__form__lead .form__input {

}
.mb .training__form__content .content__data .block__form .block__form__lead .form__input input {
    background: var(--color__bg__fill__gray__3);
    height: 60px;
    border-radius: 12px;
    color: var(--color__text__white__1);
    --input-text-color: var(--color__text__white__1);
}
.mb .training__form__content .content__data .block__form .block__form__lead .form__input input:focus {
    border: 1px solid var(--color__text__white__1);
}

.mb .training__form__content .content__data .block__form .block__form__confirm {
    color: var(--color__text__white__1);
    opacity: 0.5;
    font-size: var(--f--14);
    padding-top: 30px;
    text-align: center;
}
.mb .training__form__content .content__data .block__form .block__form__confirm a {
    color: var(--color__text__white__1);
    opacity: 1;
    text-decoration: underline;
}
/* --------------------------------------------------------------------------- */
/* training__form__section END */
/* --------------------------------------------------------------------------- */