.select__dropdown {
    position: relative;
    width: auto;
    float: left;
    max-width: 100%;
    background: var(--color__btn__fill__gray__1);
    color: var(--color__text__gray__3);
    border-radius: 12px;
    padding: 3px 5px;
    select {
        font-family: "San Francisco Text";
        font-size: 1rem;
        font-weight: var(--fw--400);
        font-size: var(--f--17);
        max-width: 100%;
        padding: 8px 24px 8px 10px;
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        margin-top: -2px;
        right: 8px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #aaa;
    }
}