/* --------------------------------------------------------------------------- */
/* training__problems__section START */
/* --------------------------------------------------------------------------- */
.dp .training__problems__section .training__problems__content {
    margin-bottom: 120px;
}
.dp .training__problems__content .content__head {
    padding-bottom: 45px;
}
.dp .training__problems__content .container__bullets .bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
}
.dp .training__problems__content .container__bullets .bullets__items .bullets__item {
    width: 100%;
    background: var(--color__bg__fill__gray__2);
    border-radius: 20px;
    padding: 20px 0;
    display: flex;
}
.dp .training__problems__content .container__bullets .bullets__items .bullets__item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dp .training__problems__content .bullets__item .item__body {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto;
    padding: 0 10px;
}
.dp .training__problems__content .bullets__item .item__body .item__icon {
    display: flex;
    margin: 0 auto;
}
.dp .training__problems__content .bullets__item .item__body .item__icon .icon__data {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dp .training__problems__content .bullets__item .item__body .item__icon .icon__data span {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    color: #00000f;
    border-radius: 50%;
    background-color: rgba(87, 203, 126, 1);
}
.dp .training__problems__content .bullets__item .item__body .item__text {
    display: grid;
    align-items: center;
}
.dp .training__problems__content .bullets__item .item__body .item__text .item__text__title {
    font-size: var(--f--16);
    line-height: 1.2;
    font-weight: var(--fw--500);
    color: var(--color__text__gray__1);
}

.mb .training__problems__section .training__problems__content {
    margin-bottom: 120px;
    padding: 0 20px;
}
.mb .training__problems__content .content__head {
    padding-bottom: 45px;
}
.mb .training__problems__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__problems__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__problems__content .container__bullets .bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-rows: auto;
}
.mb .training__problems__content .container__bullets .bullets__items .bullets__item {
    width: 100%;
    background: var(--color__bg__fill__gray__2);
    border-radius: 20px;
    padding: 20px 0;
    display: flex;
}
.mb .training__problems__content .container__bullets .bullets__items .bullets__item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.mb .training__problems__content .bullets__item .item__body {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto;
    padding: 0 10px;
}
.mb .training__problems__content .bullets__item .item__body .item__icon {
    display: flex;
    margin: 0 auto;
}
.mb .training__problems__content .bullets__item .item__body .item__icon .icon__data {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mb .training__problems__content .bullets__item .item__body .item__icon .icon__data span {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    color: #00000f;
    border-radius: 50%;
    background-color: rgba(87, 203, 126, 1);
}
.mb .training__problems__content .bullets__item .item__body .item__text {
    display: grid;
    align-items: center;
}
.mb .training__problems__content .bullets__item .item__body .item__text .item__text__title {
    font-size: var(--f--16);
    line-height: 1.2;
    font-weight: var(--fw--500);
    color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* training__problems__section END */
/* --------------------------------------------------------------------------- */