/* --------------------------------------------------------------------------- */
/* training__author__section START */
/* --------------------------------------------------------------------------- */
.dp .training__author__section .training__author__content {
    margin-bottom: 120px;
}
.dp .training__author__content .content__head {
    padding-bottom: 45px;
}
.dp .training__author__content .content__data .block__author {
    display: grid;
    margin: 0 auto;
    grid-gap: 35px;
    grid-template-rows: auto;
    background: var(--color__bg__fill__gray__2);
    padding: 30px 40px;
    border-radius: var(--br--24);
}
.dp .training__author__content .content__data .block__author .block__photo {
    display: grid;
    align-items: center;
    justify-items: center;
}
.dp .training__author__content .content__data .block__author .block__photo .photo__text {
    font-size: 20px;
    color: var(--color__text__gray__1);
}
.dp .training__author__content .content__data .block__author .block__photo img {
    background: center no-repeat;
    background-size: cover;
    border-radius: 210px;
}
.dp .training__author__content .content__data .block__author .block__text {
    font-size: var(--f--17);
    color: var(--color__text__gray__1);
}

.mb .training__author__section .training__author__content {
    margin-bottom: 120px;
    padding: 0 20px;
}
.mb .training__author__content .content__head {
    padding-bottom: 45px;
}
.mb .training__author__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__author__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__author__content .content__data .block__author {
    display: grid;
    margin: 0 auto;
    grid-gap: 35px;
    grid-template-rows: auto;
    background: var(--color__bg__fill__gray__2);
    padding: 30px 25px;
    border-radius: var(--br--24);
}
.mb .training__author__content .content__data .block__author .block__photo {
    display: grid;
    align-items: center;
    justify-items: center;
}
.mb .training__author__content .content__data .block__author .block__photo .photo__text {
    font-size: 20px;
    color: var(--color__text__gray__1);
}
.mb .training__author__content .content__data .block__author .block__photo img {
    background: center no-repeat;
    background-size: cover;
    border-radius: 150px;
    width: 300px;
    height: 300px;
}
.mb .training__author__content .content__data .block__author .block__text {
    font-size: var(--f--17);
    color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* training__author__section END */
/* --------------------------------------------------------------------------- */