/* --------------------------------------------------------------------------- */
/* card__item__block-1 START */
/* --------------------------------------------------------------------------- */
.dp .card__item__block-1 a:hover .card__item__body .card__bottom .card__item__count {
    opacity: 1;
}
.dp .card__item__block-1 .card__item__container {
}
.dp .card__item__block-1 .card__item__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--color__text__white__1);
    height: 400px;
}
.dp .card__item__block-1 .card__item__body .card__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .card__item__block-1 .card__item__body .card__img .card__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--br--16);
}
.dp .card__item__block-1 .card__item__body .card__img .card__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(78%, rgba(0, 0, 0, .7)));
    background-image: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .7) 78%);
    border-radius: var(--br--16);
}
.dp .card__item__block-1 .card__item__body .card__bottom {
    padding: 0 20px 20px 20px;
    z-index: 100;
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__cat {
    color: #d1cdcd;
    font-size: var(--f--16);
}
.dp .card__item__block-1 .card__item__body .card__top .card__item__discount {
    background: var(--color__bg__fill__violet_1);
    color: var(--color__text__white__1);
    padding: 5px 10px;
    position: absolute;
    left: 15px;
    top: -12px;
    border-radius: 12px;
    font-size: var(--f--19);
    transform: rotate(-8deg);
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__text {}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__text .card__item__title {
    color: var(--color__text__white__1);
    font-weight: var(--fw--600);
    line-height: 1.1;
    font-size: var(--f--27);
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__text .card__item__desc {
    color: var(--color__text__gray__5);
    padding-top: 5px;
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    opacity: 0.8;
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__price {
    padding-top: 10px;
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__price .price__sub {
    font-size: var(--f--19);
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__price .price__full {
    font-size: var(--f--14);
    opacity: 0.7;
}
.dp .card__item__block-1 .card__item__body .card__top {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 20px 0 0;
    align-items: flex-end;
    z-index: 100;
    width: 100%;
}
.dp .card__item__block-1 .card__item__body .card__bottom .card__item__count {
    /* background: var(--color__bg__fill__white__1); */
    /* padding: 7px 12px; */
    /* border-radius: 12px; */
    opacity: 0.8;
    color: #ffffff;
    font-size: var(--f--15);
    margin-bottom: 3px;
}
/* --------------------------------------------------------------------------- */
/* card__item__block-1 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* card__item__block-2 START */
/* --------------------------------------------------------------------------- */
.dp .block__card__items .card__item__block-2 {
    grid-column: span 1;
}
.dp .card__item__block-2 a:hover .card__item__body .card__bottom .card__item__count {
    opacity: 1;
}
.dp .card__item__block-2 .card__item__container {
    display: grid;
}
.dp .card__item__block-2 .card__item__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: var(--color__text__white__1);
    height: 400px;
}
.dp .card__item__block-2 .card__item__body .card__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .card__item__block-2 .card__item__body .card__img .card__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--br--24);
}
.dp .card__item__block-2 .card__item__body .card__img .card__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(78%, rgba(0, 0, 0, .7)));
    background-image: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .7) 78%);
    border-radius: var(--br--24);
}
.dp .card__item__block-2 .card__item__body .card__bottom {
    padding: 0 20px 20px 20px;
    z-index: 100;
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__cat {
    color: #d1cdcd;
    font-size: var(--f--15);
}
.dp .card__item__block-2 .card__item__body .card__top .card__item__discount {
    background: var(--color__bg__fill__violet_1);
    color: var(--color__text__white__1);
    padding: 5px 10px;
    position: absolute;
    left: 15px;
    top: -12px;
    border-radius: 12px;
    font-size: var(--f--19);
    transform: rotate(-8deg);
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__text {}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__text .card__item__title {
    color: var(--color__text__white__1);
    font-size: var(--f--19);
    font-weight: var(--fw--600);
    line-height: 1.2;
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__text .card__item__desc {
    color: var(--color__text__gray__5);
    padding-top: 5px;
    font-size: var(--f--16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    opacity: 0.8;
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__price {
    padding-top: 10px;
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__price .price__sub {
    font-size: var(--f--19);
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__price .price__full {
    font-size: var(--f--14);
    opacity: 0.7;
}
.dp .card__item__block-2 .card__item__body .card__top {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 20px 0 0;
    align-items: flex-end;
    z-index: 100;
    width: 100%;
}
.dp .card__item__block-2 .card__item__body .card__bottom .card__item__count {
    /* background: var(--color__bg__fill__white__1); */
    /* padding: 4px 10px; */
    /* border-radius: 12px; */
    opacity: 0.8;
    color: #ffffff;
    font-size: var(--f--15);
    margin-bottom: 3px;
}
/* --------------------------------------------------------------------------- */
/* card__item__block-2 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* card__item__block-3 START */
/* --------------------------------------------------------------------------- */
.dp .block__card__items .card__item__block-3 {
    grid-column: span 1;
}
.dp .block__card__items .card__item__block-3 .card__item__container {
    height: 400px;
    overflow: hidden;
}
.dp .card__item__block-3 .card__item__container .card__item__head {
    position: relative;
    height: 175px;
}
.dp .card__item__block-3 .card__item__head .card__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .card__item__block-3 .card__item__head .card__img .card__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--br--24);
}
.dp .card__item__block-3 .card__item__body {
    padding: 15px 15px 15px 15px;
}
.dp .card__item__block-3 .card__item__body .card__item__cat {
    font-size: var(--f--16);
    color: #818181;
    padding-bottom: 5px;
}
.dp .card__item__block-3 .card__item__body .card__item__title {
    color: var(--color__text__black__1);
    font-size: 21px;
    font-weight: var(--fw--600);
    line-height: 1;
}
.dp .card__item__block-3 .card__item__body .card__item__desc {
    color: var(--color__text__gray__1);
    padding-top: 8px;
    font-size: var(--f--16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
}
.dp .card__item__block-3 .card__item__head .card__top {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 20px 0 0;
    align-items: flex-end;
    z-index: 100;
    width: 100%;
}
.dp .card__item__block-3 .card__item__head .card__top .card__item__count {
    background: #fff;
    padding: 7px 12px;
    border-radius: 12px;
    opacity: 0.8;
    color: var(--color__text__gray__3);
    font-size: var(--f--14);
}
/* --------------------------------------------------------------------------- */
/* card__item__block-3 END */
/* --------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------- */
/* card__item__block-4 START */
/* --------------------------------------------------------------------------- */
.dp .block__card__items .card__item__block-4 {
    grid-column: span 1;
}
.dp .card__item__block-4 .card__item__container {
    display: grid;
}
.dp .card__item__block-4 .card__item__body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: var(--color__bg__fill__gray__2);
    height: 400px;
    border-radius: var(--br--24);
}
.dp .card__item__block-4 .card__item__body .card__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.dp .card__item__block-4 .card__item__body .card__img .card__img__src {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--br--16);
}
.dp .card__item__block-4 .card__item__body .card__img .card__img__src:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(78%, rgba(0, 0, 0, .7)));
    background-image: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, .7) 78%);
    border-radius: var(--br--16);
}
.dp .card__item__block-4 .card__item__body .card__bottom {
    padding: 0 20px 20px 20px;
    z-index: 100;
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__cat {
    color: #818181;
    padding-bottom: 5px;
    font-size: var(--f--14);
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__text {}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__text .card__item__title {
    color: var(--color__text__black__1);
    font-size: 21px;
    font-weight: var(--fw--600);
    line-height: 1.2;
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__text .card__item__desc {
    color: var(--color__text__gray__1);
    padding-top: 10px;
    font-size: var(--f--16);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__price {
    padding-top: 10px;
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__price .price__sub {
    font-size: var(--f--19);
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__price .price__full {
    font-size: var(--f--14);
    color: var(--color__text__gray__3);
}
.dp .card__item__block-4 .card__item__body .card__top {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 20px 0 0;
    align-items: flex-end;
    z-index: 100;
    width: 100%;
}
.dp .card__item__block-4 .card__item__body .card__bottom .card__item__count {
    /* background: var(--color__bg__fill__white__1); */
    /* padding: 7px 12px; */
    /* border-radius: 12px; */
    opacity: 0.8;
    color: var(--color__text__black__1);
    font-size: var(--f--15);
    margin-bottom: 3px;
}
/* --------------------------------------------------------------------------- */
/* card__item__block-4 END */
/* --------------------------------------------------------------------------- */