/* --------------------------------------------------------------------------- */
/* block__cats START */
/* --------------------------------------------------------------------------- */
.dp .block__cats .page__all {
    padding-bottom: 40px;
}
.dp .block__cats .block__cats__list .block__cats__items .cats__items {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -4px -12px 0;
    padding: 0;
}
.dp .block__cats .block__cats__list .block__cats__items .items__page__all {
    justify-content: center;
}
.dp .block__cats .block__cats__list .block__cats__items .cats__items .cats__item {
    margin-bottom: 8px;
    margin-right: 8px;
}
.dp .block__cats .block__cats__list .block__cats__items .cats__items .cats__item a {
    border-radius: var(--br--24);
    display: block;
    padding: 9px 20px;
    border: 1px solid var(--color__bg__fill__gray__3);
    color: var(--color__text__black__1);
}
.dp .block__cats .block__cats__list .block__cats__items .cats__items .cats__item a:hover,
.dp .block__cats .block__cats__list .block__cats__items .cats__items .active a {
    color: var(--color__text__white__1);
    background: var(--color__bg__fill__gray__4);
}

.mb .block__cats .page__all {
    padding-bottom: 40px;
}
.mb .block__cats .block__cats__list .block__cats__items .cats__items {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -4px -12px 0;
    padding: 0;
}
.mb .block__cats .block__cats__list .block__cats__items .items__page__all,
.mb .block__cats .block__cats__list .block__cats__items .items__page__cats{
    justify-content: center;
}
.mb .block__cats .block__cats__list .block__cats__items .cats__items .cats__item {
    margin-bottom: 8px;
    margin-right: 4px;
}
.mb .block__cats .block__cats__list .block__cats__items .cats__items .cats__item a {
    border-radius: var(--br--24);
    display: block;
    padding: 9px 15px;
    border: 1px solid var(--color__bg__fill__gray__3);
    color: var(--color__text__black__1);
}
.mb .block__cats .block__cats__list .block__cats__items .cats__items .cats__item a:hover,
.mb .block__cats .block__cats__list .block__cats__items .cats__items .active a {
    color: var(--color__text__white__1);
    background: var(--color__bg__fill__gray__4);
}
/* --------------------------------------------------------------------------- */
/* block__cats END */
/* --------------------------------------------------------------------------- */