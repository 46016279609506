/* --------------------------------------------------------------------------- */
/* training__steps__section START */
/* --------------------------------------------------------------------------- */
.dp .training__steps__section .training__steps__content {
    margin-bottom: 120px;
}
.dp .training__steps__content .content__head {
    padding-bottom: 45px;
}
.dp .training__steps__content .container__steps .steps__items {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
}
.dp .training__steps__content .container__steps .steps__items .steps__item {
    margin-bottom: 30px;
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body {
    background: var(--color__bg__fill__gray__4);
    color: var(--color__text__white__1);
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__details .details__duration {
    color: var(--color__text__gray__4);
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    color: var(--color__text__gray__4);
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__skills .skills__items .skill__item {
    background: var(--color__bg__fill__gray__3);
    color: var(--color__text__gray__4);
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__skills .skill__item .skill__data .skill__data__name {
    color: var(--color__text__gray__4);
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__details {
    border-bottom: 1px solid #313131;
}
.dp .training__steps__content .steps__item .item__body {
    background: var(--color__bg__fill__gray__2);
    border-radius: var(--br--24);
    padding-bottom: 40px;
}
.dp .training__steps__content .steps__item .item__body:hover {
    transform: scale(1.045);
}
.dp .training__steps__content .steps__item .item__body:hover .item__body__head .head__steps .head__steps__icon .icon__data {
    opacity: 1;
}
/* head__details */
.dp .training__steps__content .steps__item .item__body .item__body__head .head__details {
    border-radius: 24px 24px 0 0;
    padding: 20px 30px;
    border-bottom: 1px solid #e4e4e4;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__details .details__duration {
    color: var(--color__text__gray__3);
    font-size: var(--f--16);
}
/* head__steps */
.dp .training__steps__content .steps__item .item__body .item__body__head .head__steps {
    display: grid;
    margin: 0 auto;
    grid-gap: 40px;
    grid-template-columns: 125px auto;
    grid-template-rows: auto;
    padding: 40px 24px 0 24px;
    align-items: center;
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps {
    grid-template-columns: auto 125px;
    grid-row: 1;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__icon .icon__data {
    font-size: 100px;
    line-height: 80px;
    font-weight: var(--fw--600);
    opacity: 0.1;
    display: grid;
    justify-content: center;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data {
    align-items: center;
}
.dp .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps .head__steps__data {
    grid-row: 1;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__title {
    font-size: 30px;
    line-height: 1;
    padding-bottom: 10px;
    font-weight: var(--fw--600);
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    line-height: 1.2;
    color: var(--color__text__gray__1);
}
/* head__skills */
.dp .training__steps__content .steps__item .item__body .item__body__head .head__skills {
    padding: 40px 24px 0 24px;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 25px;
    grid-template-rows: auto;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items .skill__item {
    background: var(--color__bg__fill__white__1);
    padding: 25px;
    border-radius: 20px;
}
.dp .training__steps__content .steps__item .item__body .item__body__head .head__skills .skill__item .skill__data .skill__data__name {
    color: var(--color__text__gray__1);
    font-size: var(--f--16);
}

.mb .training__steps__section .training__steps__content {
    margin-bottom: 120px;
}
.mb .training__steps__content .content__head {
    padding: 0 20px 45px 20px;
}
.mb .training__steps__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__steps__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__steps__content .container__steps .steps__items {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
}
.mb .training__steps__content .container__steps .steps__items .steps__item {
    /*margin-bottom: 30px;*/
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body {
    background: var(--color__bg__fill__gray__4);
    color: var(--color__text__white__1);
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__details .details__duration {
    color: var(--color__text__gray__4);
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    color: var(--color__text__gray__4);
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__skills .skills__items .skill__item {
    background: var(--color__bg__fill__gray__3);
    color: var(--color__text__gray__4);
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__skills .skill__item .skill__data .skill__data__name {
    color: var(--color__text__gray__4);
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__details {
    border-bottom: 1px solid #313131;
}
.mb .training__steps__content .steps__item .item__body {
    background: var(--color__bg__fill__gray__2);
    padding-bottom: 40px;
}
.mb .training__steps__content .steps__item .item__body:hover .item__body__head .head__steps .head__steps__icon .icon__data {
    opacity: 1;
}
/* head__details */
.mb .training__steps__content .steps__item .item__body .item__body__head .head__details {
    padding: 20px 30px;
    border-bottom: 1px solid #e4e4e4;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__details .details__duration {
    color: var(--color__text__gray__3);
    font-size: var(--f--16);
    text-align: center;
}
/* head__steps */
.mb .training__steps__content .steps__item .item__body .item__body__head .head__steps {
    display: grid;
    margin: 0 auto;
    grid-gap: 40px;
    grid-template-rows: auto;
    padding: 40px 24px 0 24px;
    align-items: center;
    text-align: center;
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps {
    grid-row: 1;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__icon .icon__data {
    font-size: 100px;
    line-height: 80px;
    font-weight: var(--fw--600);
    opacity: 0.1;
    display: grid;
    justify-content: center;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data {
    align-items: center;
}
.mb .training__steps__content .steps__item:nth-child(2n) .item__body .item__body__head .head__steps .head__steps__data {
    grid-row: 1;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__title {
    font-size: 30px;
    line-height: 1;
    padding-bottom: 10px;
    font-weight: var(--fw--600);
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__steps .head__steps__data .steps__data__desc {
    line-height: 1.2;
    color: var(--color__text__gray__1);
}
/* head__skills */
.mb .training__steps__content .steps__item .item__body .item__body__head .head__skills {
    padding: 40px 24px 0 24px;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 25px;
    grid-template-rows: auto;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__skills .skills__items .skill__item {
    background: var(--color__bg__fill__white__1);
    padding: 25px;
    border-radius: 20px;
}
.mb .training__steps__content .steps__item .item__body .item__body__head .head__skills .skill__item .skill__data .skill__data__name {
    color: var(--color__text__gray__1);
    font-size: var(--f--16);
    text-align: center;
}
/* --------------------------------------------------------------------------- */
/* training__steps__section END */
/* --------------------------------------------------------------------------- */