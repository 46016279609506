/* --------------------------------------------------------------------------- */
/* section__bullets START */
/* --------------------------------------------------------------------------- */
.dp .training__head__section .head__section__bullets {
    background: var(--color__bg__fill__gray__2);
}
.dp .head__section__bullets .section__bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
}
.dp .section__bullets__items .section__bullets__item {
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0 35px;
}
.dp .section__bullets__items .section__bullets__item .bullet__desc {
    color: var(--color__text__gray__1);
}
.dp .section__bullets__items .section__bullets__item .bullet__count {
    font-weight: var(--fw--600);
    color: var(--color__text__black__1);
}
/* --------------------------------------------------------------------------- */
/* section__bullets END */
/* --------------------------------------------------------------------------- */