/* --------------------------------------------------------------------------- */
/* training__forwho__section START */
/* --------------------------------------------------------------------------- */
.dp .training__forwho__section .training__forwho__content {
    margin-bottom: 120px;
}
.dp .training__forwho__content .content__head {
    padding-bottom: 45px;
}
.dp .training__forwho__content .container__bullets .bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 25px;
    grid-template-rows: auto;
}
.dp .training__forwho__content .container__bullets .bullets__items .bullets__item {
    width: 100%;
    background: var(--color__bg__fill__gray__2);
    border-radius: 20px;
    padding: 20px 0;
    display: grid;
}
.dp .training__forwho__content .container__bullets .bullets__items .bullets__item:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dp .training__forwho__content .container__bullets .bullets__items .bullets__item:hover .item__body .item__icon .icon__data {
    opacity: 1;
}
.dp .training__forwho__content .bullets__item .item__body {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-columns: 100px auto;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
}
.dp .training__forwho__content .bullets__item .item__body .item__icon .icon__data {
    font-size: 75px;
    font-weight: var(--fw--600);
    opacity: 0.1;
    display: grid;
    justify-content: center;
}
.dp .training__forwho__content .bullets__item .item__body .item__text .item__text__title {
    font-size: 20px;
    line-height: 1.2;
    padding-bottom: 10px;
    font-weight: var(--fw--600);
    color: var(--color__text__black__1);
}
.dp .training__forwho__content .bullets__item .item__body .item__text .item__text__desc {
    color: var(--color__text__gray__1);
}

.mb .training__forwho__section .training__forwho__content {
    margin-bottom: 120px;
    padding: 0 20px;
}
.mb .training__forwho__content .content__head {
    padding-bottom: 45px;
}
.mb .training__forwho__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__forwho__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__forwho__content .container__bullets .bullets__items {
    display: grid;
    margin: 0 auto;
    grid-gap: 25px;
    grid-template-rows: auto;
}
.mb .training__forwho__content .container__bullets .bullets__items .bullets__item {
    width: 100%;
    background: var(--color__bg__fill__gray__2);
    border-radius: 20px;
    padding: 20px 0;
    display: grid;
}
.mb .training__forwho__content .bullets__item .item__body {
    display: grid;
    margin: 0 auto;
    grid-gap: 20px;
    grid-template-columns: 100px auto;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
}
.mb .training__forwho__content .bullets__item .item__body .item__icon .icon__data {
    font-size: 75px;
    font-weight: var(--fw--600);
    opacity: 0.1;
    display: grid;
    justify-content: center;
}
.mb .training__forwho__content .bullets__item .item__body .item__text .item__text__title {
    font-size: 20px;
    line-height: 1.2;
    padding-bottom: 10px;
    font-weight: var(--fw--600);
    color: var(--color__text__black__1);
}
.mb .training__forwho__content .bullets__item .item__body .item__text .item__text__desc {
    color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* training__forwho__section END */
/* --------------------------------------------------------------------------- */