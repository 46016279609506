.dp .block__main {
    padding-bottom: 75px;
}
.dp .block__main .block__main__container {
    justify-content: space-between;
    grid-gap: 20px;
    grid-template-columns: 60% auto;
    grid-template-rows: auto;
}
.dp .block__main .block__main__info {
    justify-content: center;
    align-items: center;
    display: flex;
}
.dp .block__main .block__main__info .main__info__text {
    line-height: 1;
    font-weight: var(--fw--600);
    text-align: center;
    /*min-height: 255px;*/
}
.dp .block__main .block__main__screen .main__screen__container {
    align-items: center;
    right: 0;
    position: relative;
    overflow: hidden;
    display: none;
}
.dp .block__main .block__main__screen img {
    width: 288%;
    background: #f3f3f2;
    padding: 30px;
    border-radius: 18px;
}