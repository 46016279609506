/* --------------------------------------------------------------------------- */
/* training__video__section START */
/* --------------------------------------------------------------------------- */
.dp .training__video__section .training__video__content {
    margin-bottom: 120px;
}
.dp .training__video__content .content__head {
    padding-bottom: 45px;
    text-align: center;
}
.dp .training__video__content .content__data .block__video {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.dp .training__video__content .content__data .block__video .video__player {
    display: grid;
    justify-content: center;
    align-items: center;
}
.dp .training__video__content .content__data .block__video .video__player .player__iframe {
    max-width: 100%;
    margin: 0 auto;
}
.dp .training__video__content .content__data .block__video .video__player .player__iframe .plyr--video {
    border-radius: 20px;
    border-radius: var(--br--24);
}
.dp .training__video__content .content__data .block__video .video__info {
}
.dp .training__video__content .content__data .block__video .video__info .video__info__title {
    font-size: var(--f--25);
    font-weight: var(--fw--700);
    color: var(--color__text__black__1);
}
.dp .training__video__content .content__data .block__video .video__info .video__info__desc {
    font-size: var(--f--17);
    color: var(--color__text__gray__1);
}

.mb .training__video__section .training__video__content {
    margin-bottom: 120px;
    padding: 0 20px;
}
.mb .training__video__content .content__head {
    padding-bottom: 45px;
}
.mb .training__video__content .content__head .section__title {
    font-size: 35px;
    line-height: 1;
    font-weight: var(--fw--700);
    padding-left: 5px;
}
.mb .training__video__content .content__head .section__desc {
    font-size: var(--f--17);
    padding-top: 10px;
    padding-left: 5px;
    color: var(--color__text__gray__1);
}
.mb .training__video__content .content__data .block__video {
    display: grid;
    margin: 0 auto;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
}
.mb .training__video__content .content__data .block__video .video__player {
    display: grid;
    justify-content: center;
    align-items: center;
}
.mb .training__video__content .content__data .block__video .video__player .player__iframe {
    max-width: 100%;
    margin: 0 auto;
}
.mb .training__video__content .content__data .block__video .video__player .player__iframe .plyr--video {
    border-radius: 20px;
    border-radius: var(--br--24);
}
.mb .training__video__content .content__data .block__video .video__info {
}
.mb .training__video__content .content__data .block__video .video__info .video__info__title {
    font-size: var(--f--25);
    font-weight: var(--fw--700);
    color: var(--color__text__black__1);
}
.mb .training__video__content .content__data .block__video .video__info .video__info__desc {
    font-size: var(--f--17);
    color: var(--color__text__gray__1);
}
/* --------------------------------------------------------------------------- */
/* training__video__section END */
/* --------------------------------------------------------------------------- */