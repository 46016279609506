/* --------------------------------------------------------------------------- */
/* page_header START */
/* --------------------------------------------------------------------------- */
.dp .page__header .header__container {
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 100%;
}
.dp .header__container .header__items {
    display: grid;
    grid-template-columns: 350px auto;
    grid-gap: 60px 35px;
    width: 100%;
    align-items: center;
    padding: 25px 0 65px 0;
}
.dp .header__container .header__items .header__logo {}
.dp .header__container .header__items .header__logo .logo__items {
    display: flex;
    align-items: center;
}
.dp .header__container .header__items .header__logo .logo__items .logo__item {
    display: inline-block;
}
.dp .header__container .header__items .header__logo .logo__items .item__lang {
    margin-left: 30px;
}
.dp .header__items .header__logo svg {
    display: flex;
    width: 190px;
    height: 30px;
}
.dp .header__items .header__logo .header__logo__text {
    color: var(--color__text__gray__1);
    padding-left: 3px;
    font-size: var(--f--16);
}
.dp .header__items .header__logo .header__logo__cls {
    font-family: Days;
    font-size: 55px;
}
.dp .header__items .header__user {
    justify-content: flex-end;
    display: flex;
}
.dp .header__items .header__user .header__user__navigate .navigate__items .nav__lang {
    text-transform: uppercase;
}
.dp .header__items .header__user .header__user__navigate .navigate__item .login {
    border: none;
    border-radius: 12px;
    padding: 13px 20px;
    background: var(--color__btn__fill__black__2);
    color: var(--color__text__white__1);
}
.dp .header__items .header__user .header__user__navigate .navigate__item .login:hover {
    background: var(--color__btn__fill__black__1);
}

.mb .page__header .header__container {
    flex-direction: row;
    align-items: center;
    display: flex;
    width: 100%;
}
.mb .header__container .header__items {
    display: grid;
    grid-template-columns: 240px auto;
    grid-gap: 60px 20px;
    width: 100%;
    align-items: center;
    padding: 25px 0 65px;
}
.mb .header__container .header__items .header__logo {
    padding-left: 15px;
}
.mb .header__container .header__items .header__user {
    padding-right: 15px;
}
.mb .header__container .header__items .item__lang {
    display: none;
}
.mb .header__items .header__logo svg {
    display: flex;
    width: 170px;
    height: 30px;
}
.mb .header__items .header__logo .header__logo__text {
    color: var(--color__text__gray__1);
    padding-left: 3px;
    font-size: var(--f--16);
}
.mb .header__items .header__logo .header__logo__cls {
    font-family: Days;
    font-size: 55px;
}
.dmb .header__items .header__user {
    justify-content: flex-end;
    display: flex;
}
.mb .header__items .header__user .header__user__navigate .navigate__item .login {
    border: none;
    border-radius: 12px;
    padding: 9px 25px;
    background: var(--color__btn__fill__black__2);
    color: var(--color__text__white__1);
}
.mb .header__items .header__user .header__user__navigate .navigate__item .login:hover {
    background: var(--color__btn__fill__black__1);
}
.mb .header__items .header__user .header__user__navigate .navigate__item {
    display: grid;
    justify-content: flex-end;
}
/* --------------------------------------------------------------------------- */
/* page_header END */
/* --------------------------------------------------------------------------- */