.block__load__more {
    margin-top: 60px;
}
.block__load__more button {
    border: none;
    border-radius: 12px;
    background: var(--color__btn__fill__black__2);
    color: var(--color__text__white__1);
    margin: 0 auto;
    width: 50%;
    height: 60px;
}