/* --------------------------------------------------------------------------- */
/* page_footer START */
/* --------------------------------------------------------------------------- */
.dp .base__content .page__footer {
    padding: 60px 0;
}
.dp .footer__container .footer__items {
    font-size: var(--f--16);
}
.dp .footer__items .footer__info .footer__info__container {
    display: grid;
    grid-gap: 60px 35px;
    align-items: center;
    grid-template-columns: 200px auto;
}
.dp .footer__items .footer__info .footer__info__container .footer__info__logo__date {
    color: var(--color__text__gray__1);
}
.dp .footer__items .footer__info .footer__info__logo svg {
    position: relative;
    left: -4px;
    width: 170px;
    height: 30px;
}
.dp .footer__items .footer__info .footer__info__logo .footer__logo__cls {
    font-family: Days;
    font-size: 55px;
}
.dp .footer__items .footer__info .footer__info__logo .footer__logo__text {
    color: var(--color__text__gray__1);
}
.dp .footer__items .footer__info .footer__info__docs {
    color: var(--color__text__gray__1);
    border-left: 1px solid #eee;
    padding-left: 45px;
    display: flex;
    justify-content: flex-end;
}
.dp .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 20px;
}
.dp .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul li a {
    color: var(--color__text__gray__1);
}
.dp .footer__items .footer__info .footer__info__docs .footer__info__docs__contact {
    padding-top: 20px;
}
.dp .footer__items .footer__addons {
    padding-top: 30px;
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    margin: 0 auto;
    color: var(--color__text__gray__1);
}

.mb .base__content .page__footer {
    padding-top: 45px;
}
.mb .base__content .page__footer .footer__container {
    color: var(--color__text__gray__1);
    padding: 30px 0 40px 0;
}
.mb .footer__items .footer__info {
    padding: 0 20px;
}
.mb .footer__items .footer__info .footer__info__container {
    display: grid;
    grid-gap: 30px 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.mb .footer__items .footer__info .footer__info__logo svg {
    position: relative;
    width: 190px;
    height: 30px;
    fill: #000000;
}
.mb .footer__items .footer__info .footer__info__logo .footer__logo__cls {
    font-family: Days;
    font-size: 55px;
}
.mb .footer__items .footer__info .footer__info__logo .footer__logo__text {
    color: var(--color__text__gray__1);
}
.mb .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul li:first-child {
    margin-top: 0;
}
.mb .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul li {
    margin-top: 10px;
}
.mb .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul {
    text-align: center;
}
.mb .footer__items .footer__info .footer__info__docs .footer__info__docs__link ul li a {
    color: var(--color__text__gray__1);
}
.mb .footer__items .footer__info .footer__info__docs .footer__info__docs__contact {
    padding-top: 40px;
}
.mb .footer__items .footer__addons {
    padding: 30px 20px 0 20px;
    margin-top: 15px;
    display: grid;
    grid-row-gap: 15px;
    border-top: 1px solid #343434;
}
.mb .footer__items .footer__addons .footer__addons__cookie {
    font-size: var(--f--14);
}
/* --------------------------------------------------------------------------- */
/* page_footer END */
/* --------------------------------------------------------------------------- */