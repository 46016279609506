:root {
  --color: #0E1420;
  --background: #FFFFFF;
  --link-hover-underline: none;
  --link-underline: none;
  --border-radius: 24px;
}

:root {
  --color__btn__fill__gray__1: #f3f3f4;
  --color__btn__fill__gray__2: #e6e6e9;
  --color__btn__fill__black__1: #000000;
  --color__btn__fill__black__2: #1e2025;
  --color__btn__fill__puple__1: #251b29;
  --color__btn__fill__white__1: #FFFFFF;
}

:root {
  --color__text__gray__1: #525252;
  --color__text__gray__2: #0000000f;
  --color__text__gray__3: #333333;
  --color__text__gray__4: #d6d6d6;
  --color__text__gray__5: #e6e6e6;
  --color__text__black__1: #0E1420;
  --color__text__white__1: #FFFFFF;
}

:root {
  --color__bg__fill__white__1: #FFFFFF;
  --color__bg__fill__gray__1: #F5F5F5;
  --color__bg__fill__gray__2: #ededed;
  --color__bg__fill__gray__3: #1e2025;
  --color__bg__fill__gray__4: #292a2f;
  --color__bg__fill__yellow_1: #fff808;
  --color__bg__fill__violet_1: #654fa4;
  --color__bg__fill__green_1: #89b04c;
  --color__bg__fill__magenta_1: #bf264c;
  --color__bg__fill__magenta_2: #bb2649;
  --color__bg__fill__puple__1: #251b29;
}

:root {
  --f--14: 14px;
  --f--15: 15px;
  --f--16: 16px;
  --f--17: 17px;
  --f--19: 19px;
  --f--21: 21px;
  --f--23: 23px;
  --f--25: 25px;
  --f--27: 27px;
}

:root {
  --fw--400: 400;
  --fw--500: 500;
  --fw--600: 600;
  --fw--700: 700;
  --fw--900: 900;
}

:root {
  --br--16: 16px;
  --br--24: 24px;
}

body {
  padding: 0;
  margin: 0;
  letter-spacing: -0.03em;
  font-family: 'San Francisco Text', ibm, manrope, muesosans;
  font-size: var(--f--17);
  /*background: var(--background);*/
  font-weight: var(--fw--400);
  line-height: 1.3;
  font-variant: unset;
  scroll-behavior: smooth;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #0e1420;
}

button {
  border: none;
  cursor: pointer;
  letter-spacing: -0.03em;
  display: block;
  text-align: center;
  font-size: 17px;
  padding: 0 20px;
  color: #fff;
  border-radius: 12px;
  font-family: 'San Francisco Text';
  height: 50px;
}

input {
  margin: 0;
  border: 0;
  background: none;
  line-height: inherit;
  color: inherit;
  outline: none;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: inherit;
  box-sizing: border-box;
  background-color: #f9f7f7;
  font-family: 'San Francisco Text';
  font-weight: var(--fw--400);
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid rgb(163 163 163 / 32%);
}

input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

label {
  font-weight: var(--fw--600);
  padding-bottom: 5px;
  padding-left: 2px;
  display: inline-block;
  font-size: 18px;
}